<template>
     <v-card :class="color" dark>
              <v-card-text style="height: 134px;" class="text-center">
                <span class="text-h6 font-weight-light">{{GAMENAME(data.game)}}</span>
                  <div class="text-gold text-shadow">
                      <template v-if="data.game == '3D'">
                      <v-btn fab class="ma-1 _numbtn"><span class="text-h4" style="color: #fff;">{{NUM[0]}}</span></v-btn>
                      <v-btn fab class="ma-1 _numbtn"><span class="text-h4" style="color: #fff;">{{NUM[1]}}</span></v-btn> 
                      <v-btn fab class="ma-1 _numbtn"><span class="text-h4" style="color: #fff;">{{NUM[2]}}</span></v-btn> 
                    </template>
                    <template v-else-if="data.game == '4D'">
                      <v-btn fab  class="ma-1 _numbtn"><span class="text-h4" style="color: #fff;">{{NUM[0]}}</span></v-btn>
                      <v-btn fab  class="ma-1 _numbtn"><span class="text-h4" style="color: #fff;">{{NUM[1]}}</span></v-btn> 
                      <v-btn fab  class="ma-1 _numbtn"><span class="text-h4" style="color: #fff;">{{NUM[2]}}</span></v-btn> 
                      <v-btn fab  class="ma-1 _numbtn"><span class="text-h4" style="color: #fff;">{{NUM[3]}}</span></v-btn> 
                    </template>
                    <template v-else>
                      <v-btn fab  class="ma-1 _numbtn"><span class="text-h4" style="color: #fff;">{{NUM[0]}}</span></v-btn>
                      <v-btn fab  class="ma-1 _numbtn"><span class="text-h4" style="color: #fff;">{{NUM[1]}}</span></v-btn> 
                    </template>
                   </div>
                   <span class="text-caption">{{data.time}} RESULT</span>
                   
                </v-card-text>
                <v-card-actions>
                  <v-divider/>
                  <v-spacer></v-spacer>
                  <v-btn color="#febd00"  rounded large dark @click="$router.push(GameLink(data.game))"> PLAY NOW!</v-btn>
                  <v-spacer></v-spacer>
                  <v-divider/>
                </v-card-actions>
            </v-card>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "secondary"
    },
    icon: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      default: ()=>{}
    },
    sup: {
      type: String,
      default: ''
    }
  },
  computed:{
    NUM(){
        var n = this.data.combination.split(' - ')
        return n
    }
  },
  methods: {

    GameLink(game) {
      var link = "/play-2d"
      switch(game) {
        case "2D":
          link = "/play-2d"
          break
        case "3D":
          link = "/play-3d"
          break
        case "STL":
          link = "/play-stl"
          break
          case "4D":
          link = "/play-4D"
          break
        case "STL-ILOILO-PROVINCE":
          link = "/play-stl-iloilo-province"
          break
        case "2D-STL-ILOILO-CITY":
          link = "/play-2d-stl-iloilo-city"
          break
          case "3D-STL-ILOILO-CITY":
          link = "/play-3d-stl-iloilo-city"
          break
        default:
          link = "/play-2d"
      }
      return link
    }
  }

}
</script>